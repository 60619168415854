import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy"/>
    <div className="privacy-container">
      <h2>Aviso de Privacidad.</h2>
      <p>Para los efectos de este aviso de privacidad, se entiende por Arquetipo estudio, Sociedad Anónima de Capital Variable, así como a cualquiera de sus sociedades filiales, controladoras y subsidiarias.</p>
      <p>El sitio de internet de Arquetipo estudio, es decir, el ubicado en (www.arquetipoestudio.com) tiene acceso abierto al público en general y se puede navegar libremente en él sin la necesidad por parte de los usuarios de compartir ni divulgar datos personales. El presente aviso de privacidad únicamente regula la información que es compartida a través de los vínculos de contacto y acceso a clientes que se encuentran en el propio sitio.</p>

      <h2>Nombre y domicilio del responsable de los datos personales.</h2>
      <p>Arquetipo estudio, una Sociedad Anónima de Capital Variable debidamente constituida conforme a las Leyes de los Estados Unidos Mexicanos, con domicilio en Calle Zamora 36 - 4 Colonia Condesa, Alcaldía Cuauhtémoc, C.P. 06140, y es responsable del tratamiento de sus datos personales con estricto apego y de conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en posesión de los particulares, Lo anterior, en términos de lo establecido en los artículos 6, 7, 8, 14, 16, 17, 18, 19, 20 y 21. Su reglamento, así como la legislación aplicable.</p>

      <h2>Obtención de datos personales.</h2>
      <p>Arquetipo estudio, obtiene datos personales a los cuales da tratamiento de acuerdo con el presente aviso de privacidad cuando los titulares de datos personales los proporcionan directamente; ya sea a través de visitas del sitio de internet, vía telefónica, por correspondencia, mediante el uso de nuestros servicios en línea y a través de otras fuentes permitidas por la Legislación de Protección de Datos Personales a través de medios electrónicos de conformidad con lo establecido en el presente aviso.</p>
      <p>Arquetipo estudio, recaba datos personales de forma directa cuando su titular voluntariamente se los proporciona por cualquier medio a funcionarios, empleados, representantes, contratistas o terceras personas autorizadas para recabarlos en nombre del estudio, por cualquiera de las razones que, en forma enunciativa más no limitativa, se detallan a continuación:</p>

      <ol className="data">
        <li>Con el objeto de obtener información y/o conocer con mayor detalle los servicios de Arquetipo estudio.</li>
        <li>Los proporcionados por aspirantes a posiciones de trabajo vacantes o por empleados.</li>
        <li>Aquellos compartidos al momento de presentar alguna Queja, sugerencia o comentario.</li>
      </ol>

      <p>Los datos que por lo general se obtienen por dichos medios pueden ser, entre otros:</p>

      <ol className="data">
        <li>Para el caso de personas morales, ya sea proveedores, contratistas, asesores o consultores: razón social, acta constitutiva, nombre, datos de contacto y poderes del representante legal.</li>
        <li>Para el caso de clientes: datos de la compañía para la cual trabaja y puesto que desempeñe; proyecto en el cual participa con Arquetipo estudio, opinión del servicio y sugerencias (todos ellos en lo individual o en su conjunto.</li>
      </ol>

      <h2>Datos personales sensibles y datos personales patrimoniales.</h2>
      <p>Con el objeto de cumplir con algunas de las finalidades previstas en el presente aviso de privacidad, siempre que sea aplicable, Arquetipo estudio, podrá recabar datos personales sensibles y patrimoniales, tales como aquellos que se refieren a estado de salud únicamente para el caso de los empleados, funcionarios y aspirantes, sueldo obtenido, número de cuenta y CLABE bancaria para depósitos de nómina, entre otros.</p>
      <p>Arquetipo estudio, manifiesta su compromiso con los titulares de dichos datos personales sensibles y patrimoniales de preservarlos y darles tratamiento bajo las más estrictas medidas de seguridad a efecto de garantizar su confidencialidad.</p>

      <h2>Si usted es nuestro cliente su información será utilizada para:</h2>
      <p>Desarrollar nuevos servicios, conocer el mercado y el desempeño de Arquetipo estudio, dar seguimiento a nuestros servicios a fin de alcanzar la satisfacción del cliente, servicios de atención al cliente, generar informes estadísticos, y para dar cumplimiento a la legislación aplicable y a los requerimientos de autoridades federales y locales, generar nuestra base de datos y realizar la actualización de sus datos personales y preferencias con la finalidad de ofrecerle aquellos servicios que se adecuen a sus necesidades.</p>
      <p>Adicionalmente sus datos personales podrán ser tratados para las siguientes finalidades secundarias: informarle sobre publicidad, así como para realizar encuestas de calidad del servicio. Al no manifestar su negativa para el tratamiento de sus datos personales paras las finalidades comerciales, se entiende que usted otorga su consentimiento.</p>
      <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>

      <ul>
        <li>Datos de identificación y contacto como: nombre, correo electrónico, fecha de nacimiento, teléfono, país de residencia.</li>
        <li>Datos bancarios y de facturación como: Número de cuenta, CLABE Interbancaria, R.F.C., datos de facturación.</li>
      </ul>

      <h2>Medidas de Seguridad</h2>
      <p>Arquetipo estudio ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.</p>

      <h2>Finalidades y uso de sus datos personales.</h2>
      <p>Arquetipo estudio, utilizará los datos personales para: i) atender las solicitudes de información acerca de nuestros servicios ii) atender cualquier queja, pregunta o comentario presentada; iii) crear bases de datos para fines de calidad de nuestros servicios; iv) cumplir con la legislación de datos personales; v) tener un canal de comunicación y contacto con los clientes.</p>

      <h2>Revocación de datos personales.</h2>
      <p>En cualquier momento, la persona o personas que hayan otorgado datos personales y/o datos personales sensibles y patrimoniales, de conformidad con lo establecido en el presente aviso de privacidad, podrá solicitar, la eliminación de los mismos, contactando y solicitándolo al encargado de manejo de datos personales al correo electrónico alejandro@arquetipoestudio.com De la misma manera en caso de recibir cualquier comunicación por parte de empleados, funcionarios, subsidiarias y/o filiales, podrán solicitar a esa misma cuenta de correo electrónico el ser eliminados de la lista de correspondencia.</p>

      <h2>Derechos de acceso, rectificación, oposición y cancelación (derechos arco).</h2>
      <p>Para cualquier información acerca de este aviso de privacidad o para el ejercicio de cualesquiera de sus derechos conforme a lo establecido en la legislación de datos personales, incluyendo sin limitación sus derechos de acceso, rectificación, cancelación y oposición, favor de contactar a la persona encargada de manejo de datos personales al correo electrónico.</p>
      <p>Para el ejercicio de los derechos arco, Arquetipo estudio, ha implementado el siguiente mecanismo:</p>
      <p>1.- Los derechos arco deberán ser ejercidos por los titulares de datos personales o por su representante, de acuerdo con lo siguiente:</p>
      <ol className="data-letter">
        <li>Los titulares de datos personales deberán acreditar su identidad a través de la presentación de copia de su documento de identificación oficial, exhibiendo el original para su cotejo.</li>
        <li>El representante de los titulares de datos personales deberá acreditar:</li>
        <ol className="data">
          <li>La identidad de los titulares de datos personales;</li>
          <li>Su identidad; y</li>
          <li>La existencia de la representación a través de la que se faculta al representante a actuar en nombre y representación de los titulares de datos personales, mediante Instrumento Público o Carta Poder firmada ante dos testigos o declaración en comparecencia personal de los titulares de datos personales.</li>
          <li>Nombre de la persona que realizó la evaluación de servicio y puesto de la misma, en su caso.</li>
        </ol>
      </ol>
      <p>2.- Los titulares de datos personales o su representante deberán presentar una solicitud mediante escrito libre dirigido al C. Alejandro de León Barrios, encargado del manejo de datos personales al correo electrónico alejandro@arquetipoestudio.com en la sección correspondiente de la página (www.arquetipoestudio.com) con los siguientes datos:</p>
      <ol className="data-letter">
        <li>Nombre del titular, domicilio, teléfono y correo electrónico;</li>
        <li>La descripción clara y precisa de los datos personales respecto de los cuales el titular busca ejercer algún derecho arco;</li>
        <li>Cualquier elemento o documento que facilite la localización de los datos personales en las bases de datos y;</li>
        <li>En el caso de solicitudes de rectificación de datos personales, el titular deberá además señalar las modificaciones que pretende llevar a cabo y aportar la documentación con la que sustente su petición.</li>
      </ol>
      <p>3.- Arquetipo estudio, le comunicará por correo electrónico al titular la determinación que adopte en un plazo máximo de veinte días contados a partir de la fecha en que recibió</p>
      <p>4.- Si la determinación mencionada en el punto anterior es procedente, Arquetipo estudio, la hará efectiva dentro de los siguientes veinte días contados a partir de la fecha en que le comunique la respuesta respectiva al titular. Este plazo podrá ser ampliado cuando así lo justifiquen las circunstancias del caso.</p>

      <h2>Transferencia de datos personales.</h2>
      <p>Arquetipo estudio, podrá compartir los datos personales con los empleados, contratistas, prestadores de servicios y personal autorizado, quienes tratarán y conservarán dichos datos personales. Asimismo, Arquetipo estudio podrá compartir los datos personales con autoridades gubernamentales en los Estados Unidos Mexicanos o en el extranjero, instituciones académicas y aliados comerciales. De igual forma se reserva el derecho de transferir los datos personales a cualquier adquirente de la empresa, subsidiaria, controladora u otras sociedades o vehículos designados por estas en caso de ser necesario. Arquetipo estudio, no compartirá o transferirá los datos personales a terceros salvo en los casos previstos en el presente aviso de privacidad, o en la legislación de datos personales o en cualquier otra legislación aplicable en los Estados Unidos Mexicanos.</p>

      <h2>Cambios al aviso de privacidad y legislación aplicable.</h2>
      <p>Arquetipo estudio, se reserva el derecho de cambiar los términos y condiciones de este aviso de privacidad, notificándolo mediante el apartado específico en la página de internet (www.arquetipoestudio.com).</p>
    </div>
  </Layout>
)

export default PrivacyPage
